import { useState } from "react";
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import { ThemeOptions } from '@mui/material/styles';

export const themeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#97C66E',
        },
        secondary: {
            main: '#ff0000',
        },
    },
};
const theme = createTheme(themeOptions);

function App() {
  const [auth, setAuth] = useState(false);
  const location = useLocation();

  return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/login" element={<Login setAuth={setAuth} />} />
          <Route path="/signup" element={<Signup setAuth={setAuth} />} />
          <Route
              path="/"
              element={
                <Login setAuth={setAuth} />
              }
          />
        </Routes>
      </ThemeProvider>
  );
}

export default App;
